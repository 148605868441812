<!--
 * @Descripttion: 用户注册页
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 08:51:17
-->

<template>
<div class="contariner">
  <h2 class="home">智采竞价系统</h2>
  <div class="form-container" style="text-align:center;padding-top:40px;">
       <span style="">当前用户暂时没有权限，请联系管理员授权</span>     
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          name: '',
          linkMan: '',
          mobile: '',
          addr: '',
        }
      }
    },
    methods: {
      onSubmit() {
        console.log('submit!');
      }
    }
  }
</script>

<style lang="scss" scoped>

  .contariner {
    height: 100vh;
    background-color: #F5F5F5;
    .home {
      color: #00CCB2;
      font-size: 20px;
      line-height: 50px;
      height: 50px;
      width: 100%;
      padding-left: 15px;
      background-color: #fff;
    }
    .form-container {
      margin: 30px 50px 30px 50px;
      padding-bottom: 50px;
      background-color: #fff;
      border-radius: 10px;
      .register {
        width: 600px;
        margin: 0 auto;
        border-radius: 10px;
        .register-title {
          font-size: 40px;
          font-weight: bold;
          text-align: center;
          padding: 60px 0;
        }
      }
    }
  }


</style>
